<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-6">

                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('addresses::street_types.columns.name').ucFirst() }} *</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('addresses::street_types.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>

                <div class="col-lg-6">
                    <label
                        :class="{'text-danger': states.locale === false}"
                        class="form-label"
                        for="locale"
                    >{{ $t('addresses::street_types.columns.locale').ucFirst() }} *</label
                    >
                    <select
                        id="locale"
                        v-model="model.locale"
                        :class="{'border-danger': states.locale === false}"
                        class="form-control"
                    >
                        <option :value="(model.id ? null : undefined)">
                            {{ $t('addresses::street_types.placeholders.locale').ucFirst() }}
                        </option>
                        <option value="hu">hu</option>
                        <option value="en">en</option>
                    </select>
                    <div :class="{'d-block': states.locale === false}" class="invalid-feedback">{{
                            errors.locale
                        }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "StreetTypeForm",

    emits: ['saved'],

    props: {
        streetType: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'streetType/loading',
            'states': 'streetType/states',
            'errors': 'streetType/errors'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.streetType)),
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`streetType/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/master-data/street-types/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },
    },

    watch: {
        streetType: function () {
            this.model = JSON.parse(JSON.stringify(this.streetType))
        },
    },

    created() {
        // console.log(this.streetType)
    }
}
</script>

<style scoped>

</style>
